import React from 'react';
import Container from '../UI/Container';
import GridContainer from '../UI/GridContainer';
import GridItem from '../UI/GridItem';
import { Link } from 'gatsby';
import YouTube from 'react-youtube';
import getYouTubeID from 'get-youtube-id';
//
import cssModule from './index.module.scss';
//
import { translations } from '../../translations/shared';
import { bookAFlight } from '../../translations/navigation';

const AirTime = ({ description, heading, lang, media: { image, video } }) => {
  const options = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  return (
    <section className={`${cssModule.airTime} air-time`}>
      <Container>
        <GridContainer small="auto / 1fr" large="auto / 3fr 1fr 4fr 4fr">
          <h2>{heading}</h2>
          <GridItem large="1 / 3 / 2 / 4">
            <p className="p-large no-b-m">{description}</p>
          </GridItem>
          <GridItem
            className={cssModule.buttonWrapper}
            large="1 / 4 / 2 / 5"
            justifySelf="flex-end"
          >
            <Link to={bookAFlight[lang].path} className="lnk primary">
              {translations[lang].buttons.bookAFlight}
            </Link>
          </GridItem>
        </GridContainer>
        <GridContainer>
          {video ? (
            <div className="video-holder no-b-p">
              <YouTube videoId={getYouTubeID(video)} opts={options} />
            </div>
          ) : (
            <img src={image} width="100%" alt="Book a flight" />
          )}
        </GridContainer>
      </Container>
    </section>
  );
};

export default AirTime;
