import React from 'react';
import './index.scss';

const Button = ({ onClick, className, children, type }) => {
  return (
    <button type={type} onClick={onClick} className={`btn ${className}`}>
      {children}
    </button>
  );
};

export default Button;
