import React from 'react';
import { GridItem } from '..';
//
import Aircraft from '../Aircraft';
import Quote from '../Quote';
import Container from '../UI/Container';
import GridContainer from '../UI/GridContainer';
//
import cssModule from './index.module.scss';
//

const TheAircraft = ({ heading, description, quote, author, publisher, aircrafts = [], lang }) => {
  return (
    <section className={cssModule.theAircraft}>
      <Container>
        <GridContainer
          className={cssModule.gridOuter}
          small="auto / 1fr, 0 32px"
          large="auto / 4fr 6fr, 0 32px"
        >
          <h2 className={cssModule.sectionTitle}>{heading}</h2>
          <div>
            <p className={`${cssModule.airCraftInfo} p-large no-b-m`}>{description}</p>
            <GridContainer
              small="auto / 1fr, 0 32px"
              tablet="auto / 1fr 1fr, 0 32px"
              large="auto / 1.5fr 1fr, 0 32px"
              xlarge="auto / 3fr 1fr 2fr"
              className={cssModule.quoteWrapper}
            >
              <Quote quote={quote} author={author} publisher={publisher} />
            </GridContainer>
          </div>
        </GridContainer>
        <div>
          {aircrafts &&
            aircrafts.map(aircraft => <Aircraft key={aircraft.slug} {...aircraft} lang={lang} />)}
        </div>
      </Container>
    </section>
  );
};

export default TheAircraft;
