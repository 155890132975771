export const translations = {
  en: {
    formFields: {
      purpose: 'Purpose',
      aircraft: 'Aircraft',
      prefix: 'Prefix',
      firstName: 'First Name',
      lastName: 'Last Name',
      street: 'Street',
      houseNumber: 'House/Building Number',
      zipCode: 'Zip/Postal Code',
      city: 'City',
      country: 'Country',
      phone: 'Telephone Number (Mobile preferred)',
      clothSize: 'Clothing Size',
      email: 'Email',
      age: 'Age',
      comment: 'Comment (optional)',
      termsAndConditions: 'I accept the Terms & Conditions',
      submitText: "Let's fly",
      iAmFlying: 'I am flying',
      bookingAsGift: 'I am booking a flight as a gift',
    },
  },
  ge: {
    formFields: {
      purpose: 'Zweck',
      aircraft: 'Flugzeug',
      prefix: 'Anrede',
      firstName: 'Vorname',
      lastName: 'Nachname',
      street: 'Straße',
      houseNumber: 'Hausnummer',
      zipCode: 'Postleitzahl',
      city: 'Stadt',
      country: 'Land',
      phone: 'Telefonnummer (Mobiltelefon bevorzugt)',
      clothSize: 'Kleidergröße',
      email: 'E-Mail',
      age: 'Alter',
      comment: 'Kommentar',
      termsAndConditions: 'Ich akzeptiere die Allgemeinen Geschäftsbedingungen',
      submitText: "Let's fly",
      iAmFlying: 'Ich fliege',
      bookingAsGift: 'Ich buche einen Flug als Geschenk',
    },
  },
};
