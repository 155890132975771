import React from 'react';
//
import Container from '../UI/Container';
import GridContainer from '../UI/GridContainer';
import GridItem from '../UI/GridItem';
//
import cssModule from './index.module.scss';
//

const Landing = ({ heading, image, descriptionItems }) => {
  return (
    <section className={cssModule.landing}>
      <Container>
        <GridContainer large="auto / 1fr 4fr 2fr 5fr, 0 32px">
          <GridItem small="1 / 1 / 2 / 4">
            <h2 className={cssModule.sectionTitle}>{heading}</h2>
          </GridItem>
          <GridItem large="2 / 2 / 3 /3">
            {descriptionItems &&
              descriptionItems.map(item => (
                <>
                  <h6>{item.title}</h6>
                  <p className="p-large no-b-m">{item.description}</p>
                </>
              ))}
          </GridItem>
          <GridItem small="2 / 1 / 3 / 4" large="2 / 4 / 3 / 5">
            <img src={image} width="100%" />
          </GridItem>
        </GridContainer>
      </Container>
    </section>
  );
};

export default Landing;
