export const translations = {
  en: {
    labels: {
      typeLabel: 'AIRCRAFT TYPE',
      firstFlightLabel: 'FIRST FLIGHT',
      wingSpanLabel: 'WING SPAN (m)',
      fuselageLabel: 'FUSELAGE LENGTH (m)',
      maxHeightLabel: 'MAXIMUM HEIGHT (m)',
      gLimitsLabel: 'G-LIMITS',
      emptyWeightLabel: 'Empty Weight (kg)',
      maxWeightLabel: 'Max Weight (kg)',
      fuelCapacityLabel: 'Fuel capacity (L)',
      engineLabel: 'Engine',
      powerLabel: 'Power (BHP)',
      propellerLabel: 'PROPELLER',
      vsoLabel: 'VSO, Stall Speed (km/h)',
      vneLabel: 'VNE, Never Exceed Speed by Design (km/h)',
      rollRateLabel: 'Roll Rate',
      climbRateLabel: 'Climb Rate at Sea Level',
    },
  },
  ge: {
    labels: {
      typeLabel: 'AIRCRAFT TYPE',
      firstFlightLabel: 'FIRST FLIGHT',
      wingSpanLabel: 'WING SPAN (m)',
      fuselageLabel: 'FUSELAGE LENGTH (m)',
      maxHeightLabel: 'MAXIMUM HEIGHT (m)',
      gLimitsLabel: 'G-LIMITS',
      emptyWeightLabel: 'Empty Weight (kg)',
      maxWeightLabel: 'Max Weight (kg)',
      fuelCapacityLabel: 'Fuel capacity (L)',
      engineLabel: 'Engine',
      powerLabel: 'Power (BHP)',
      propellerLabel: 'PROPELLER',
      vsoLabel: 'VSO, Stall Speed (km/h)',
      vneLabel: 'VNE, Never Exceed Speed by Design (km/h)',
      rollRateLabel: 'Roll Rate',
      climbRateLabel: 'Climb Rate at Sea Level',
    },
  },
};
