import React from 'react';
//
import CloseIcon from '../../assets/svg/close-icon.svg';
import { translations } from './specs-translations';
import cssModule from './index.module.scss';

const SpecsModal = ({
  onClose,
  climbRate,
  comingSoon,
  description,
  emptyWeight,
  engine,
  firstFlight,
  fuelCapacity,
  fuselage,
  gNegative,
  gPositive,
  image,
  maxHeight,
  maxSpeed,
  maxWeight,
  minimumFlightTime,
  power,
  propeller,
  registration,
  rollRate,
  slug,
  title,
  type,
  vne,
  vso,
  wingSpan,
  lang,
}) => {
  const {
    labels: {
      typeLabel,
      firstFlightLabel,
      wingSpanLabel,
      fuselageLabel,
      maxHeightLabel,
      gLimitsLabel,
      emptyWeightLabel,
      maxWeightLabel,
      fuelCapacityLabel,
      engineLabel,
      powerLabel,
      propellerLabel,
      vsoLabel,
      vneLabel,
      rollRateLabel,
      climbRateLabel,
    },
  } = translations[lang];
  return (
    <>
      <div className={`${cssModule.specsModalOverlay}`}></div>
      <div className={cssModule.specsModal}>
        <div className={cssModule.modalHeader}>
          <h6>PERFORMANCE DATA</h6>
          <a href="#" onClick={onClose}>
            <CloseIcon />
          </a>
        </div>
        <div className={cssModule.dataGrid}>
          {type && (
            <>
              <p className={cssModule.label}>{typeLabel}</p>
              <p className={cssModule.spec}>{type}</p>
            </>
          )}
          {firstFlight && (
            <>
              <p className={cssModule.label}>{firstFlightLabel}</p>
              <p className={cssModule.spec}>{firstFlight}</p>
            </>
          )}
          {wingSpan && (
            <>
              <p className={cssModule.label}>{wingSpanLabel}</p>
              <p className={cssModule.spec}>{wingSpan}</p>
            </>
          )}
          {fuselage && (
            <>
              <p className={cssModule.label}>{fuselageLabel}</p>
              <p className={cssModule.spec}>{wingSpan}</p>
            </>
          )}
          {maxHeight && (
            <>
              <p className={cssModule.label}>{maxHeightLabel}</p>
              <p className={cssModule.spec}>{maxHeight}</p>
            </>
          )}
          {gPositive && (
            <>
              <p className={cssModule.label}>{gLimitsLabel}</p>
              <p className={cssModule.spec}>
                + {gPositive} <br /> - {gNegative}
              </p>
            </>
          )}
          {emptyWeight && (
            <>
              <p className={cssModule.label}>{emptyWeightLabel}</p>
              <p className={cssModule.spec}>{emptyWeight}</p>
            </>
          )}
          {maxWeight && (
            <>
              <p className={cssModule.label}>{maxWeightLabel}</p>
              <p className={cssModule.spec}>{maxWeight}</p>
            </>
          )}
          {fuelCapacity && (
            <>
              <p className={cssModule.label}>{fuelCapacityLabel}</p>
              <p className={cssModule.spec}>{fuelCapacity}</p>
            </>
          )}
          {engine && (
            <>
              <p className={cssModule.label}>{engineLabel}</p>
              <p className={cssModule.spec}>{engine}</p>
            </>
          )}
          {power && (
            <>
              <p className={cssModule.label}>{powerLabel}</p>
              <p className={cssModule.spec}>{power}</p>
            </>
          )}
          {propeller && (
            <>
              <p className={cssModule.label}>{propellerLabel}</p>
              <p className={cssModule.spec}>{propeller}</p>
            </>
          )}
          {vso && (
            <>
              <p className={cssModule.label}>{vsoLabel}</p>
              <p className={cssModule.spec}>{vso}</p>
            </>
          )}
          {vso && (
            <>
              <p className={cssModule.label}>{vsoLabel}</p>
              <p className={cssModule.spec}>{vso}</p>
            </>
          )}
          {vne && (
            <>
              <p className={cssModule.label}>{vneLabel}</p>
              <p className={cssModule.spec}>{vne}</p>
            </>
          )}
          {rollRate && (
            <>
              <p className={cssModule.label}>{rollRateLabel}</p>
              <p className={cssModule.spec}>{rollRate}</p>
            </>
          )}
          {climbRate && (
            <>
              <p className={cssModule.label}>{climbRateLabel}</p>
              <p className={cssModule.spec}>{climbRate}</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default SpecsModal;
