import React from 'react';
//
import cssModule from './index.module.scss';
//

const HeroTagLine = ({ text, PhoneContact }) => {
  return (
    <div className={`${cssModule.heroTagLine} hero-tagline`}>
      <h1>{text}</h1>
      {PhoneContact && <PhoneContact />}
    </div>
  );
};

export default HeroTagLine;
