import React from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs';
//
import cssModule from './index.module.scss';

const CustomTabs = props => {
  return (
    <>
      <Tabs onChange={props.onTabChange}>
        <TabList className={cssModule.tablist}>
          {props.tabs &&
            props.tabs.map(tab => {
              return (
                <Tab className="btn text-white tab uppercase" key={tab}>
                  {tab}
                </Tab>
              );
            })}
        </TabList>
        <TabPanels>
          {props.panels &&
            props.panels.map((panel, index) => {
              return <TabPanel key={index}>{panel}</TabPanel>;
            })}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default CustomTabs;
