import React from 'react';
//
import cssModule from './index.module.scss';
//

const BgVideo = ({ video }) => {
  return (
    <figure className={cssModule.bgVideo}>
      <video loop={true} autoPlay={true} muted={true} playsInline={true}>
        <source src={video} type="video/mp4" />
      </video>
    </figure>
  );
};

export default BgVideo;
