import { Link } from 'gatsby';
import React, { useState, useRef } from 'react';
import Button from '../UI/Button';
import Container from '../UI/Container';
import GridContainer from '../UI/GridContainer';
//
import cssModule from './index.module.scss';
//
import { translations } from '../../templates/book-a-flight/translations';
import { terms } from '../../translations/navigation';

const getTermsConditionsTranslation = lang => {
  const termsLink = terms[lang].path;

  return lang === 'en' ? (
    <>
      I accept the {''}
      <Link className="underline lnk" to={termsLink}>
        Terms & Conditions
      </Link>{' '}
    </>
  ) : (
    <>
      Ich akzeptiere {''}
      <Link className="underline lnk" to={termsLink}>
        die Allgemeinen Geschäftsbedingungen
      </Link>{' '}
    </>
  );
};

const customTranslations = {
  en: {
    mr: 'Mr',
    mrs: 'Mrs',
  },
  ge: {
    mr: 'Herr',
    mrs: 'Frau',
  },
};

const BookFlightForm = ({ lang, aircrafts, selectedAircraft }) => {
  const [clothSize, setClothSize] = useState('');
  const [isDdlOpen, toggleDdl] = useState(false);
  const [clothColorClass, setClothColor] = useState('');
  const clothSelectRef = useRef();

  const handleClothSize = value => {
    setClothSize(value);
    setClothColor('selected');
    clothSelectRef.current.setAttribute('data-before', value);
  };

  const isChecked = (aircraft, index) => aircraft.slug === selectedAircraft || index === 0;

  const {
    formFields: {
      purpose,
      aircraft,
      prefix,
      firstName,
      lastName,
      street,
      houseNumber,
      zipCode,
      city,
      country,
      phone,
      clothSize: cSize,
      email,
      age,
      comment,
      submitText,
      iAmFlying,
      bookingAsGift,
    },
  } = translations[lang];

  return (
    <Container>
      <GridContainer
        tablet="auto / 0.5fr, 0"
        justifyContent="center"
        className={cssModule.bookFlightForm}
      >
        <form method="post" data-netlify="true" name="contact">
          <input type="hidden" name="form-name" value="contact" />
          <div className={cssModule.formIntro}>
            <legend>{purpose}</legend>
            <div className={cssModule.inputGroup}>
              <input type="radio" name="purpose" id="flying" defaultChecked required />
              <label htmlFor="flying">{iAmFlying}</label>
            </div>
            <div className={cssModule.inputGroup}>
              <input type="radio" name="purpose" id="gift" />
              <label htmlFor="gift">{bookingAsGift}</label>
            </div>
            <legend>{aircraft}</legend>
            {aircrafts &&
              aircrafts.map((aircraft, index) => (
                <div key={aircraft.slug} className={cssModule.inputGroup}>
                  <input
                    type="radio"
                    name="aircraft"
                    id="745"
                    checked={isChecked(aircraft, index)}
                  />
                  <label htmlFor="745">{aircraft.title}</label>
                </div>
              ))}

            <legend>{prefix}</legend>
            <div className={cssModule.inputGroup}>
              <input type="radio" name="prefix" id="mr" />
              <label htmlFor="mr">{customTranslations[lang].mr}</label>
            </div>
            <div className={cssModule.inputGroup}>
              <input type="radio" name="prefix" id="ms" />
              <label htmlFor="ms">{customTranslations[lang].mrs}</label>
            </div>
          </div>

          <div className={cssModule.textInputs}>
            <label htmlFor="name">{firstName}</label>
            <input type="text" name="name" id="name" placeholder={firstName} required />

            <label htmlFor="surname">{lastName}</label>
            <input type="text" name="surname" id="surname" placeholder={lastName} required />

            <label htmlFor="street">{street}</label>
            <input type="text" name="street" id="street" placeholder={street} required />

            <label htmlFor="location">{houseNumber}</label>
            <input type="text" name="location" id="location" placeholder={houseNumber} required />

            <label htmlFor="zip">{zipCode}</label>
            <input type="text" name="zip" id="zip" placeholder={zipCode} required />

            <label htmlFor="city">{city}</label>
            <input type="text" name="city" id="city" placeholder={city} required />

            <label htmlFor="country">{country}</label>
            <input type="text" name="country" id="country" placeholder={country} required />

            <label htmlFor="phone">{phone}</label>
            <input type="text" name="phone" id="phone" placeholder={phone} required />

            <div className="relative">
              {/*  */}
              <label
                ref={clothSelectRef}
                data-before={cSize}
                htmlFor="cloth-size"
                className={`${cssModule.clothLabel} ${clothColorClass}`}
              >
                {cSize}
              </label>
              <input
                type="checkbox"
                id="cloth-size"
                name="cloth-size"
                className={`${cssModule.customList} relative`}
                value={clothSize}
                checked={clothSize || false}
                onClick={() => toggleDdl(curr => !curr)}
              />
              <ul
                className={`no-bullet hide ${
                  isDdlOpen ? cssModule.clothSizeUl : ''
                } ${clothColorClass}`}
              >
                <li>
                  <button type="button" onClick={() => handleClothSize('S')}>
                    S
                  </button>
                </li>
                <li>
                  <button type="button" onClick={() => handleClothSize('L')}>
                    L
                  </button>
                </li>
                <li>
                  <button type="button" onClick={() => handleClothSize('XL')}>
                    XL
                  </button>
                </li>
                <li>
                  <button type="button" onClick={() => handleClothSize('XXL')}>
                    XXL
                  </button>
                </li>
              </ul>
            </div>

            <label htmlFor="email">{email}</label>
            <input type="email" name="email" id="email" placeholder="Email" required />

            <label htmlFor="age">{age}</label>
            <input type="text" name="age" id="age" placeholder={age} required />

            <label htmlFor="email">{comment}</label>
            <textarea name="comment" rows="12" placeholder={comment}></textarea>
          </div>
          <input
            type="checkbox"
            className={cssModule.termsInput}
            id="terms-conditions"
            name="terms-conditions"
            required
          />
          <label htmlFor="terms-conditions" className={cssModule.termsLabel}>
            {getTermsConditionsTranslation(lang)}
          </label>
          <Button type="submit" className="btn primary expanded" onClick={(e) => {
            if(typeof window !== "undefined") {
              window.gtag('event', 'conversion', { 'send_to': 'AW-10803035658/Asq4CKipgIMDEIr8pJ8o' })
            }
          }}>
            {submitText}
          </Button>
        </form>
      </GridContainer>
    </Container>
  );
};

export default BookFlightForm;
