import { Link } from 'gatsby';
import React from 'react';
import Container from '../UI/Container';
import GridContainer from '../UI/GridContainer';
//
import cssModule from './index.module.scss';
//
import Logo from '../../assets/svg/logo.svg';
import FooterIcon from '../../assets/svg/footer-icon.svg';

import { footerRoutes } from '../../translations/navigation';

const Footer = ({ lang }) => {
  let date = new Date();
  return (
    <section className={cssModule.footer}>
      <Container>
        <GridContainer
          small="auto / 1fr 1fr, 0 32px"
          tablet="auto / 2fr min-content"
          large="auto / 2fr 1fr"
          className={cssModule.footerGrid}
          alignItems="flex-start"
        >
          <ul>
            {footerRoutes.map(item =>
              item.external ? (
                <li key={item[lang].text}>
                  <a href={item[lang].path} target="_blank" className="lnk lnk-footer">
                    {item[lang].text}
                  </a>
                </li>
              ) : (
                <li key={item[lang].text}>
                  <Link to={item[lang].path} className="lnk lnk-footer">
                    {item[lang].text}
                  </Link>
                </li>
              )
            )}
          </ul>
          <Link to="/" className={`lnk ${cssModule.logoWrapper}`}>
            <Logo />
          </Link>
          <FooterIcon className={cssModule.footerIcon} />
          <p
            className={`${cssModule.copyright} text-white`}
          >{`© ${date.getFullYear()} 3rd Dimension`}</p>
        </GridContainer>
      </Container>
    </section>
  );
};

export default Footer;
