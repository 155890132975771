import React, { useState } from 'react';
import GridContainer from '../UI/GridContainer';
import { Link } from 'gatsby';
import SpecsModal from '../SpecsModal/index';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
//
import cssModule from './index.module.scss';
//
import Divder from '../../assets/svg/aircraft-divder-line.svg';

import { translations } from '../../translations/shared';
import { bookAFlight } from '../../translations/navigation';

const Aircraft = ({ lang, ...rest }) => {
  const [openModal, setOpenModal] = useState(false);
  const targetElement =
    typeof document !== 'undefined' ? document.getElementsByClassName('specs-modal') : null;
  openModal && window.innerWidth > 1024
    ? disableBodyScroll(targetElement, {
        reserveScrollBarGap: true,
      })
    : enableBodyScroll(targetElement);

  const onModalClose = e => {
    setOpenModal(false);
    e.preventDefault();
  };

  return (
    <article className={cssModule.aircraft}>
      {openModal && <SpecsModal onClose={onModalClose} lang={lang} {...rest} />}
      <GridContainer small="auto / 1fr, 0 32px" xlarge="auto / 1fr 1fr">
        <img src={rest.image} className={cssModule.aircraftImg} width="100%" />
        <div className={cssModule.aircraftInfo}>
          <h4 className="margin-bottom-s">{rest.title}</h4>
          <h4 className="text-gray margin-bottom-s">{rest.registration}</h4>
          <Divder className="margin-bottom-m" />
          <GridContainer small="auto / 1fr 1fr" className={cssModule.specs} alignItems="flex-start">
            <p className="text-gray p-small uppercase margin-bottom-xs">G-limits</p>
            <p className="text-gray p-small uppercase margin-bottom-xs">Power</p>
            <p className="text-gray p-small uppercase margin-bottom-xs">Max Speed</p>
            <p className={`${cssModule.glimitValue} uppercase featured`}>
              +{rest.gPositive}
              <span className="sub">-{rest.gNegative}</span>
            </p>
            <p className={`${cssModule.powerValue} uppercase featured`}>
              {rest.power}
              <span className="sub">bhp</span>
            </p>
            <p className={`${cssModule.speedValue} uppercase featured`}>
              {rest.maxSpeed}
              <span className="sub">km/h</span>
            </p>
            <a className="lnk bordered black uppercase" onClick={() => setOpenModal(!openModal)}>
              {translations[lang].buttons.viewFullSpecs}
            </a>
          </GridContainer>
          <hr />
          <GridContainer small="auto / 1fr, 0 64px" xlarge="auto / 1fr 1fr" alignItems="flex-start">
            <p className="no-b-m">{rest.description}</p>
            <Link
              to={rest.comingSoon ? undefined : `${bookAFlight[lang].path}?slug=${rest.slug}`}
              className={`lnk primary ${cssModule.bookFLight} ${
                rest.comingSoon ? cssModule.disabledBtn : ''
              }`}
            >
              {rest.comingSoon ? translations[lang].buttons.comingSoon : translations[lang].buttons.bookAFlight}
            </Link>
          </GridContainer>
        </div>
      </GridContainer>
    </article>
  );
};

export default Aircraft;
