import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
//
import Flex from '../UI/Flex';
import Container from '../UI/Container';
//
import cssModule from './index.module.scss';
import { translations } from '../../translations/shared';

const GiftFlight = ({ caption, heading, link, image, lang }) => {
  const bgImage = { '--bg-image': `url("${image}")` };

  return (
    <section style={bgImage} className={cssModule.giftFlight}>
      <Container>
        <Flex className={`flex-container`}>
          <Flex className={cssModule.bgImage}>
            <Flex small="100%" tablet="80%" xlarge="33%">
              {heading && <h3>{heading}</h3>}
              {caption && <p>{caption}</p>}
              {link && (
                <Link to={link} className="lnk lnk-note text-white">
                  {translations[lang].buttons.buyVoucher}
                </Link>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </section>
  );
};

export default GiftFlight;
