import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import YouTube from 'react-youtube';
import getYouTubeID from 'get-youtube-id';
//
import GridContainer from '../UI/GridContainer';
//
import cssModule from './index.module.scss';
import GridItem from '../UI/GridItem';
import Container from '../UI/Container';
//
import { translations } from '../../translations/shared';

const InfoSection = ({ description, link, heading, image, lang, video }) => {
  const options = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <section className={cssModule.infoSection}>
      <Container>
        <GridContainer small="auto / 1fr, 0 32px" tablet="auto / 1fr 1fr" large="auto / 1fr 1.75fr">
          <GridItem tablet="1 /1 /2 /3">{heading && <h3>{heading}</h3>}</GridItem>
          <GridItem className={`${cssModule.indentedSection}`}>
            <p className="p-large">{description}</p>
            {link && (
              <Link to={link} className="read-more">
                {translations[lang].buttons.readMore}
              </Link>
            )}
          </GridItem>
          <div className="">
            {video ? (
              <div className="video-holder no-b-p">
                <YouTube videoId={getYouTubeID(video)} opts={options} />
              </div>
            ) : (
              <img src={image} width="100%" />
            )}
          </div>
        </GridContainer>
      </Container>
    </section>
  );
};

export default InfoSection;
