import React from 'react';
//
import 'lightbox-react/style.css';
//
import LightboxReact from 'lightbox-react';
import './index.scss';

const Lightbox = ({ images, selectedImage, handleClose, handlePrevRequest, handleNextRequest }) => {
  const array = [];

  images.forEach(image => image.src && array.push(<img src={image.src} />));

  return (
    <LightboxReact
      enableZoom={false}
      clickOutsideToClose={true}
      mainSrc={array[selectedImage]}
      nextSrc={array[(selectedImage + 1) % array.length]}
      prevSrc={array[(selectedImage + array.length - 1) % images.length]}
      onCloseRequest={handleClose}
      onMovePrevRequest={handlePrevRequest(selectedImage, array.length)}
      onMoveNextRequest={handleNextRequest(selectedImage, array.length)}
    />
  );
};

export default Lightbox;
