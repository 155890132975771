export const translations = {
  en: {
    buttons: {
      read: 'Read',
      readMore: 'Read more',
      bookAFlight: 'Book a flight',
      comingSoon: "Be patient he will land shortly",
      buyVoucher: 'Buy a voucher',
      viewFullSpecs: 'View full specs',
      viewAllNews: 'All Articles & News',
      readFlightInstructions: 'Read flight instructions',
    },
  },
  ge: {
    buttons: {
      read: 'Lesen',
      readMore: 'Mehr Info',
      bookAFlight: 'Jetzt Buchen',
      comingSoon: "Sei geduldig, er landet in kürze",
      buyVoucher: 'Gutschein kaufen',
      viewFullSpecs: 'Vollständige Spezifikation',
      viewAllNews: 'Alle Artikel & News',
      readFlightInstructions: 'Read flight instructions',
    },
  },
};
