import React, { useEffect, useState } from 'react';
//
import cssModule from './index.module.scss';
//

/**
 * Format date into military time (mmss)
 * @param {Date} date Date object
 */
const toMilitaryFormat = date => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const h = hours > 9 ? hours : `0${hours}`;
  const m = minutes > 9 ? minutes : `0${minutes}`;

  return `${h}${m}`;
};

// GRENCHEN AIRPORT
const LON = 7.4111716273525134;
const LAT = 47.181880852939976;

const translations = {
  en: {
    wind: 'Wind speed',
    queryStringLangKey: 'en',
    area: 'Grenchen area weather',
    localTime: 'Local Time',
  },
  ge: {
    wind: 'windgeschwindigkeit',
    queryStringLangKey: 'de',
    area: 'Wetter in der Region Grenchen',
    localTime: 'Ortszeit',
  },
};

const httpCurrentWeather = () => {
  return fetch(
    `https://api.openweathermap.org/data/2.5/weather?lat=${LAT}&lon=${LON}&appid=7e5142debed4d45f25c0cef6e99070e5&units=metric`,
    {
      headers: {
        Accept: 'application/json',
      },
    }
  )
    .then(res => res.json())
    .catch(err => err);
};

const getQuery = (lang = 'en') => `*[_type == "settings" && i18n_lang == "${lang}"][0] {
  email,
  phone,
  i18n_lang,
  metadata {
      title,
      description,
      keywords,
    'image': image.asset->url
    }
}`;

const httpWebsiteInfo = (lang = 'en') => {
  return fetch(
    `https://bfk8bpqm.api.sanity.io/v1/data/query/production?query=${encodeURIComponent(
      getQuery(lang)
    )}`,
    {
      headers: {
        Authorization: `Bearer sk44LIYn4F6KbrCYLIyZwQNaJpdw3tyUDzbI5ZzkTjE3O0lfJSJO8aWEc5py3o5ajaqYADlPCQoKkTKdbpVGlAcKOO6sRQZ3OG9ONiccMASUnDu90xPuws9lJzZIc3n7hLrwITGWwlY1D7s6wU8tE9u6GFdm461x5TiE6zAFiSw5IrURhK95`,
      },
    }
  )
    .then(res => res.json())
    .catch(err => err);
};

const HeaderInfo = ({ visibleClass = '', lang = 'en' }) => {
  const [websiteInfo, setWebsiteInfo] = useState({ phone: '', email: '' });
  const [currentTime, setCurrentTime] = useState(new Date());
  const [weather, setWeather] = useState({ description: '', speed: null, temp: null });

  // current time effect
  useEffect(() => {
    const timeout = setInterval(() => {
      const minutes = new Date().getMinutes();
      if (minutes != currentTime.getMinutes()) setCurrentTime(new Date());
    }, 5000); // 5s

    return () => clearInterval(timeout);
  }, []);

  // weather effect
  useEffect(() => {
    const getCurrentWeather = async () => {
      try {
        const {
          weather,
          main: { temp },
          wind: { speed },
        } = await httpCurrentWeather();
        const { description } = weather[0];

        setWeather(() => ({ description, speed, temp }));
      } catch (err) {
        console.log({ err }); // no error handling
      }
    };

    getCurrentWeather();
  }, []);

  // fetch website info (phone, email)
  useEffect(() => {
    const getWebsiteInfo = async () => {
      try {
        const {
          result: { phone, email },
        } = await httpWebsiteInfo(lang);

        setWebsiteInfo(() => ({ phone, email }));
      } catch (err) {
        console.log({ err });
      }
    };

    getWebsiteInfo();
  }, []);

  const { phone, email } = websiteInfo;
  const { description, speed, temp } = weather;

  return (
    <>
      <div className={`${cssModule.headerInfo} ${cssModule[visibleClass]}`}>
        <ul className={cssModule.contactInfo}>
          <li>
            <a href={`tel:${phone}`} className="lnk underline">
              {phone}
            </a>
          </li>
          <li>
            <a href="mailto:info@3rd-dimension.ch" className="lnk underline">
              {email}
            </a>
          </li>
        </ul>
        <ul className={cssModule.weatherInfo}>
          {!description && !temp && !speed ? null : (
            <li className="bold">{translations[lang].area}</li>
          )}
          {description && <li className={cssModule.weatherDescription}>{description}</li>}
          {temp && <li className={cssModule.temperature}>{Number(temp).toFixed(0)}°C</li>}
          {speed && (
            <li className={cssModule.conditions}>
              {translations[lang].wind} {Number(speed).toFixed(1)} m/s
            </li>
          )}
        </ul>
        <ul className={cssModule.timeInfo}>
          <li className="bold">
            <li className="bold">{translations[lang].localTime}</li>
          </li>
          <li>{toMilitaryFormat(currentTime)}</li>
        </ul>
      </div>
    </>
  );
};

export default HeaderInfo;
