import React from 'react';
import YouTube from 'react-youtube';
//
import { Container } from '../../components';
import { getYoutubeID } from '../../utils/shared';
//
import cssModule from './index.module.scss';

const VideosGallery = ({ videos }) => {
  const options = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <Container>
      <div className={`${cssModule.videosGrid} video-holder`}>
        {videos &&
          videos.map(({ src: url }) => (
            <YouTube key={url} videoId={getYoutubeID(url)} opts={options} />
          ))}
      </div>
    </Container>
  );
};

export default VideosGallery;
