import { Link } from 'gatsby';
import React from 'react';
import Button from '../UI/Button';
import { navigate } from 'gatsby';

//
import cssModule from './index.module.scss';
//
import { navigationRoutes } from '../../translations/navigation';

const Navigation = ({ lang, translationPath, slug }) => {
  return (
    <nav className={cssModule.navigation}>
      <ul className={cssModule.pageNav}>
        {navigationRoutes &&
          navigationRoutes.map(item => (
            <li key={item[lang].text}>
              <Link to={item[lang].path} className="lnk lnk-nav">
                {item[lang].text}
              </Link>
            </li>
          ))}
      </ul>
      <ul className={cssModule.langPicker}>
        <li>
          <Button
            className="bordered"
            onClick={() => {
              // const path = slug ? `${translationPath}?slug=${slug}` : translationPath;
              navigate(translationPath);
            }}
          >
            EN/DE
          </Button>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
