import { Link } from 'gatsby';
import React from 'react';
import Container from '../UI/Container';
import GridContainer from '../UI/GridContainer';
import GridItem from '../UI/GridItem';
//
import cssModule from './index.module.scss';
//
import { translations } from '../../translations/shared';

const TakeOff = ({ description, heading, link, lang }) => {
  return (
    <section className={cssModule.takeOff}>
      <Container>
        <GridContainer large="auto / 3fr 1fr 4fr 4fr">
          <h2>{heading}</h2>
          <GridItem large="1 / 3 / 2 / 4">
            <p className="p-large">{description}</p>
            <Link target="_blank" to={link} className="lnk lnk-additional">
              {translations[lang].buttons.readFlightInstructions}
            </Link>
          </GridItem>
        </GridContainer>
      </Container>
    </section>
  );
};

export default TakeOff;
