import React from 'react';
import { Link } from 'gatsby';
//
import cssModule from './index.module.scss';
import { translations } from '../../translations/shared';
import { news as newsRoute } from '../../translations/navigation';
//
const NewsCard = ({ image, slug, title, lang, hasBgColor = false }) => {
  /* TODO: military background should only be on news page */

  return (
    <article className={hasBgColor ? cssModule.newsCardWithBg : cssModule.newsCard}>
      <Link
        to={newsRoute[lang].path + `/${slug}`}
        className={`lnk ${cssModule.cardWrapper} relative`}
      >
        {/* <Img
          fluid={{ ...data.file.childImageSharp.fluid, aspectRatio: 516 / 344 }}
          className="margin-bottom-m"
        /> */}

        {/* TODO: this needs to be set as a bg image probably */}
        <img src={image} width="100%" className="margin-bottom-s" />
      </Link>
      <div className={cssModule.innerGrid}>
        <h6 className>{title}</h6>
        <Link to={newsRoute[lang].path + `/${slug}`} className="lnk lnk-note">
          {translations[lang].buttons.read}
        </Link>
      </div>
    </article>
  );
};

export default NewsCard;
