export const translations = {
  en: {
    pilot: {
      birthday: 'Date of birth',
      occupation: 'Occupation',
      licenses: 'Pilot licenses',
      flyingHours: 'Flying hours',
      career: 'Career',
    },
  },
  ge: {
    pilot: {
      birthday: 'Geburtsdatum',
      occupation: 'Beruf',
      licenses: 'Pilot licenses',
      flyingHours: 'Flugstunden',
      career: 'Career',
    },
  },
};
