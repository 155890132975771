import React, { useState } from 'react';
import { ThumbGrid, Container } from '../../components';
import LightBox from '../LightBox';
//
import './index.scss';

const PhotosGallery = ({ images }) => {
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  console.log(images);
  const handleOpen = i => e => {
    console.log(i);
    setShowLightbox(true);
    setSelectedImage(i);
  };
  const handleClose = () => {
    setShowLightbox(false);
    setSelectedImage(null);
  };
  const handlePrevRequest = (i, length) => e => {
    setSelectedImage((i - 1 + length) % length);
  };
  const handleNextRequest = (i, length) => e => {
    setSelectedImage((i + 1) % length);
  };

  return (
    <>
      <ThumbGrid images={images} handleOpen={handleOpen} />
      {showLightbox && (
        <LightBox
          images={images}
          handleClose={handleClose}
          handleNextRequest={handleNextRequest}
          handlePrevRequest={handlePrevRequest}
          selectedImage={selectedImage}
        />
      )}
    </>
  );
};

export default PhotosGallery;
