import React from 'react';
import Masonry from "../masonry-layout"
import ImagesLoaded from 'react-images-loaded';
//

const ThumbGrid = ({ images, handleOpen, classes }) => {

  const init = () => {
    var grid = document.querySelector('.grid-cont');
    let msnry = new Masonry( grid, {
      // options...
      itemSelector: '.grid-masonory-item',
      gutter: 15,
      percentPosition: true
    });

  } 
   
  return (
      <ImagesLoaded
        elementType={'ul'} // defaults to 'div'
        done={init}
        className="grid-cont"
      >
        <div class="grid-sizer"></div>
        {images.map((image, i) => (
                <img key={i} onClick={handleOpen(i)} src={image.src} className={`grid-masonory-item btn ${(i == 3 || i == 8) && `grid-masonory-item--width2`}`}/>
        ))}
        </ImagesLoaded>
  );
};

export default ThumbGrid;
