import React from 'react';
import Container from '../UI/Container';
import GridContainer from '../UI/GridContainer';
//
import cssModule from './index.module.scss';
//

const Conditions = ({ heading, description, conditions }) => {
  return (
    <Container>
      <GridContainer
        tablet="auto / 0.5fr, 0"
        justifyContent="center"
        className={cssModule.conditionsGrid}
      >
        <div className={cssModule.conditionsBox}>
          <h6>{heading}</h6>
          <GridContainer tablet="auto / 1fr 1fr, 0px">
            <p className="p-small">{description}</p>
            <ul>{conditions && conditions.map(item => <li>{item}</li>)}</ul>
          </GridContainer>
        </div>
      </GridContainer>
    </Container>
  );
};

export default Conditions;
