import React from 'react';
import { Link } from 'gatsby';

import Container from '../UI/Container';
import GridContainer from '../UI/GridContainer';
//
import cssModule from './index.module.scss';
// import Avatar from '../../assets/svg/avatar.svg';
//
import { bookAFlight } from '../../translations/navigation';
import { translations } from '../../templates/company/translations';
import { translations as sharedTranslations } from '../../translations/shared';

function formatBirthday(date) {
  if (!date) return null;
  const d = new Date(date);
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

  return `${da}-${mo}-${ye}`;
}

const SinglePilot = ({ name, occupation, license, flyingHours, birthday, career, image, lang }) => {
  return (
    <article className={cssModule.singlePilot}>
      <Container>
        <GridContainer small="auto / 1fr, 0 32px" tablet="auto / 4fr 6fr 2fr">
          <h2 className={cssModule.singlePilotHeading}>{name}</h2>
          <div className={cssModule.pilot}>
            <GridContainer
              small="auto / 1fr, 0"
              tablet="auto / 1fr 1fr"
              className={cssModule.pilotInfo}
            >
              <div className="text-left">
                <p className="p-small text-gray uppercase">{translations[lang].pilot.birthday}</p>
                <p className="p-large margin-bottom-m">{formatBirthday(birthday)}</p>
                <p className="p-small text-gray uppercase">{translations[lang].pilot.occupation}</p>
                <p className="p-large margin-bottom-m">{occupation}</p>
                {/* <p className="p-small text-gray uppercase">{translations[lang].pilot.licenses}</p>
                <p className="p-large margin-bottom-m">{license}</p> */}
                <p className="p-small text-gray uppercase">
                  {translations[lang].pilot.flyingHours}
                </p>
                <p className="p-large no-b-m">{flyingHours}</p>
              </div>
              <div className={cssModule.avatar}>
                <img src={image} alt="pilot-image" />
              </div>
            </GridContainer>
            <div className={`${cssModule.career} text-left`}>
              <h6>{translations[lang].pilot.career}</h6>
              <p className="p-large no-b-m">{career}</p>
              <Link to={bookAFlight[lang].path} type="button" className="btn primary">
                {sharedTranslations[lang].buttons.bookAFlight}
              </Link>
            </div>
          </div>
        </GridContainer>
      </Container>
    </article>
  );
};

export default SinglePilot;
