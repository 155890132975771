import React from 'react';
import styles from './index.module.scss';

const Container = props => {
  return (
    <div className={`${styles.container} ${props.className}`} style={props.style}>
      {props.children}
    </div>
  );
};

export default Container;
