export const supportedLanguage = {
  en: 'en',
  ge: 'de',
};

export const home = {
  en: {
    path: '/',
    text: 'Home',
  },
  ge: {
    path: `/${supportedLanguage['ge']}`,
    text: 'Home',
  },
};

export const flight = {
  en: {
    path: '/flight',
    text: 'The flight',
  },
  ge: {
    path: `/${supportedLanguage['ge']}/flight`,
    text: 'Der Flug',
  },
};

export const pricing = {
  en: {
    path: '/pricing',
    text: 'Pricing',
  },
  ge: {
    path: `/${supportedLanguage['ge']}/pricing`,
    text: 'Preise',
  },
};

export const bookAFlight = {
  en: {
    path: '/book-a-flight',
    text: 'Book a flight',
  },
  ge: {
    path: `/${supportedLanguage['ge']}/book-a-flight`,
    text: 'Jetzt Buchen',
  },
};

export const about = {
  en: {
    path: '/about-us',
    text: 'About us',
  },
  ge: {
    path: `/${supportedLanguage['ge']}/about-us`,
    text: 'Über uns',
  },
};

export const gallery = {
  en: {
    path: '/gallery',
    text: 'Gallery',
  },
  ge: {
    path: `/${supportedLanguage['ge']}/gallery`,
    text: 'Galerie',
  },
};

export const news = {
  en: {
    path: '/news',
    text: 'Articles & News',
  },
  ge: {
    path: `/${supportedLanguage['ge']}/news`,
    text: 'Artikel & News',
  },
};

export const instructions = {
  en: {
    path: '/instructions',
    text: 'Instructions',
  },
  ge: {
    path: `/${supportedLanguage['ge']}/instructions`,
    text: 'Anweisungen',
  },
};

export const terms = {
  en: {
    path: '/terms-conditions',
    text: 'T&C',
  },
  ge: {
    path: `/${supportedLanguage['ge']}/terms-conditions`,
    text: 'Allgemeine Geschäftsbedingungen',
  },
};

export const instagram = {
  external: true,
  en: {
    path: 'https://instagram.com/tln3yz',
    text: 'Instagram',
  },
  ge: {
    path: 'https://instagram.com/tln3yz',
    text: 'Instagram',
  },
};

export const fortis = {
  external: true,
  en: {
    path: ' https://www.fortis-swiss.com/',
    text: 'Fortis Watches',
  },
  ge: {
    path: ' https://www.fortis-swiss.com/',
    text: 'Fortis Uhren',
  },
};

export const youtube = {
  external: true,
  en: {
    path: ' https://youtube.com/channel/UCVJjnUtErDF8jmscejDPq0g',
    text: 'Youtube',
  },
  ge: {
    path: ' https://youtube.com/channel/UCVJjnUtErDF8jmscejDPq0g',
    text: 'Youtube',
  },
};

export const contact = {
  en: {
    path: '/contact',
    text: 'Contact us',
  },
  ge: {
    path: `/${supportedLanguage['ge']}/contact`,
    text: 'Kontakt',
  },
};

export const impressum = {
  en: {
    path: '/impressum',
    text: 'impressum',
  },
  ge: {
    path: `/${supportedLanguage['ge']}/impressum`,
    text: 'impressum',
  },
};

export const navigationRoutes = [home, flight, pricing, bookAFlight, about, gallery, news];
export const footerRoutes = [instructions, terms, instagram, contact, impressum, youtube, fortis];
