import React from 'react';
import { GridContainer, GridItem } from '../../components/index';
//
import cssModule from './index.module.scss';

const Quote = ({ author, quote, publisher }) => {
  return (
    <>
      <p className={`p-quote no-b-m quote ${cssModule.quote}`}>{quote}</p>
      <GridItem xlarge="1 /3 /1 /4" className={`${cssModule.author} author`}>
        <p className="bold p-small">{author} </p>
        <p className="p-small no-b-m">{publisher}</p>
      </GridItem>
    </>
  );
};

export default Quote;
