export const translations = {
  en: {
    headings: {
      latestNews: 'Latest Articles & news',
    },
  },
  ge: {
    headings: {
      latestNews: 'Neuste Artikel & News',
    },
  },
};
