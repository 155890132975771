export const translations = {
  en: {
    minutes: 'minutes',
    perMinute: 'per minute',
    minFlightTime: 'Minimum flight time',
  },
  ge: {
    minutes: 'minuten',
    perMinute: 'pro Minute',
    minFlightTime: 'Minimum Flugzeit',
  },
};
