import { Link } from 'gatsby';
import React from 'react';
//
import cssModule from './index.module.scss';
//
import { bookAFlight } from '../../translations/navigation';
import { translations } from '../../templates/pricing/translations';
import { translations as sharedTranslations } from '../../translations/shared';

const getTotalPrice = (pricePerMinute, minimumFlightTime) =>
  Number(pricePerMinute * minimumFlightTime).toFixed(2);

const AircraftCard = ({ title, slug, registration, pricePerMinute, minimumFlightTime, lang }) => {
  return (
    <article className={cssModule.aircraftCard}>
      <div>
        <h4>{title}</h4>
        <h4 className="text-gray no-b-m">{registration}</h4>
      </div>
      <p className={`featured no-b-m ${cssModule.price}`}>
        CHF {Number(pricePerMinute).toFixed(2)}{' '}
        <span className="uppercase">{translations[lang].perMinute}</span>
      </p>
      <div className="">
        <p className="bold no-b-m">{translations[lang].minFlightTime}</p>
        <p className="margin-bottom-m">
          {minimumFlightTime} {translations[lang].minutes} (CHF{' '}
          {getTotalPrice(pricePerMinute, minimumFlightTime)})
        </p>
      </div>
      <Link to={`${bookAFlight[lang].path}?slug=${slug}`} className="lnk primary expanded">
        {sharedTranslations[lang].buttons.bookAFlight}
      </Link>
    </article>
  );
};

export default AircraftCard;
