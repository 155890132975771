import React, { useState } from 'react';
import { Link } from 'gatsby';
import { InView } from 'react-intersection-observer';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
//
import useWindowDimensions from '../../hooks/useWindowDimensions';
//
import BgVideo from '../BgVideo';
import Button from '../UI/Button';
import Logo from '../../assets/svg/logo.svg';
import Hamburger from '../../assets/svg/hamburger-icon.svg';
import CloseHamburger from '../../assets/svg/close-icon.svg';

import { GridContainer, Container, Navigation, NoticeBox, HeaderInfo, HeroTagLine } from '../index';
//
import cssModule from './index.module.scss';
import { home } from '../../translations/navigation';

const Header = ({
  lang,
  caption,
  heading,
  className,
  background = {},
  PhoneContact,
  EmailContact,
  ...rest
}) => {
  const { video, image } = background;
  //
  const [fixed, setFixed] = useState(false);
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [stickyHeaderStyles, setStickyHeaderStyles] = useState();
  //
  let targetElement = typeof document !== 'undefined' ? document.querySelector('body') : null;
  let fixedClass = hamburgerOpen ? 'fixed' : '';
  let visibleClass = hamburgerOpen ? 'showResponsiveMenu' : '';

  const handleStickyStyles = inView => {
    if (!inView) {
      setStickyHeaderStyles({
        '--transition': 'opacity',
        '--position': 'fixed',
        '--top': '8px',
        '--opacity': '1',
        '--animation': 'running',
      });
    } else {
      setStickyHeaderStyles({
        '--opacity': '0',
        '--transition': 'opacity',
        '--animation': 'paused',
      });
    }
  };

  const handleHamburgerClick = () => {
    setHamburgerOpen(!hamburgerOpen);
    setFixed(!fixed);
  };
  //
  hamburgerOpen ? disableBodyScroll(targetElement) : enableBodyScroll(targetElement);
  const bgImage = image
    ? {
        '--bg-image': `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${image}")`,
      }
    : { '--bg-image': '' };

  return (
    <section className={`${cssModule.header} ${className} header`} style={bgImage}>
      {video && <BgVideo video={video} />}
      <Container className={cssModule.container} style={stickyHeaderStyles}>
        <GridContainer
          small="auto / min-conent, 0 32px"
          tablet="min-content auto min-content / .2fr 1fr .2fr"
          large="min-content auto min-content / .22fr 1fr .22fr"
          xlarge="min-content auto / 200px 1fr"
          className={`${cssModule.headerGrid} ${fixedClass} header-wrap padding-top-m`}
        >
          <InView
            threshold={1}
            rootMargin="60px"
            as="div"
            onChange={handleStickyStyles}
            className={cssModule.logoWrapper}
          >
            {!hamburgerOpen && (
              <Link to={home[lang].path}>
                <Logo />
              </Link>
            )}
          </InView>
          <HeaderInfo visibleClass={visibleClass} lang={lang} />
          <Navigation lang={lang} {...rest} />
          <HeroTagLine text={heading} lang={lang} PhoneContact={PhoneContact} />
          {caption && <NoticeBox caption={caption} lang={lang} />}
          {EmailContact && <EmailContact />}
        </GridContainer>
        <Button onClick={handleHamburgerClick}>
          {!hamburgerOpen ? (
            <Hamburger className={`${cssModule.hamburgerSvg}`} />
          ) : (
            <CloseHamburger className={`${cssModule.hamburgerSvg}`} />
          )}
        </Button>
      </Container>
    </section>
  );
};

export default Header;
