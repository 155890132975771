import React from 'react';
import { Link } from 'gatsby';

import NewsCard from '../NewsCard';
import Container from '../UI/Container';
import GridContainer from '../UI/GridContainer';
//
import cssModule from './index.module.scss';
import { translations } from '../../translations/shared';
import { news as newsRoute } from '../../translations/navigation';
import { translations as homeTranslations } from '../../templates/home/translations';

const LatestNews = ({ news, lang }) => {
  if (!news || !news.length) return null;

  return (
    <section className={cssModule.latestNews}>
      <Container>
        <GridContainer
          className={cssModule.latestNewsWrapper}
          small="auto / 1fr"
          tablet="auto / 1fr 230px"
          alignItems="flex-start"
        >
          <h2>{homeTranslations[lang].headings.latestNews}</h2>

          <Link to={newsRoute[lang].path} className="lnk primary expanded">
            {translations[lang].buttons.viewAllNews}
          </Link>
          <GridContainer small="auto / 1fr" className={`${cssModule.newsGrid}`}>
            {news.map(item => (
              <NewsCard {...item} key={item.slug} lang={lang} />
            ))}
          </GridContainer>
        </GridContainer>
      </Container>
    </section>
  );
};

export default LatestNews;
