import React from 'react';
import Flex from '../UI/Flex';
import { Link } from 'gatsby';
//
import cssModule from './index.module.scss';
//
import { translations } from '../../translations/shared';
import { bookAFlight } from '../../translations/navigation';

const NoticeBox = ({ caption, lang }) => {
  return (
    <div className={`${cssModule.noticeBox}`}>
      <Flex justifyContent="space-between" className="flex-container">
        <Flex small="100%" tablet="50%">
          {caption &&
            caption.map((item, index) => (
              <p key={item} className={index === caption.length - 1 ? 'no-b-m' : ''}>
                {item}
              </p>
            ))}
        </Flex>
        <Flex small="100%" tablet="30%" large="40%">
          <Link to={bookAFlight[lang].path} className="primary lnk expanded">
            {translations[lang].buttons.bookAFlight}
          </Link>
        </Flex>
      </Flex>
    </div>
  );
};

export default NoticeBox;
